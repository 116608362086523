<template>
  <v-card :loading="loading === 1" class="px-3" flat>
    <v-card-title>
      <v-icon v-if="icon" class="mr-2">{{ icon }}</v-icon>
      {{ item.text }}
      <span v-if="data && property && sub" class="text--secondary body-2 ml-2">
        ( {{ data[property][item.value][sub] }} )
      </span>
    </v-card-title>
    <v-card-text v-if="title !== 'hide'" class="text-h3 white--text font-weight-bold">
      <span>{{ title }}</span>
      <span class="text-subtitle-1 font-weight-bold ml-1">{{ currency }}</span>
    </v-card-text>
    <slot />
  </v-card>
</template>

<script>
export default {
  props: {
    loading: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      default() {
        return null;
      },
    },
    property: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: null,
    },
    currency: {
      type: String,
      default: null,
    },
    sub: {
      type: [String, Number],
      default: null,
    },
  },
  computed: {
    title() {
      if (this.data && typeof this.data[this.property][this.item.value] !== 'object') {
        return this.data[this.property][this.item.value];
      }
      if (this.data && this.item.lastCount && typeof this.data[this.property][this.item.value] === 'object') {
        return this.data[this.property][this.item.value][this.item.lastCount];
      }
      return 'hide';
    },
  },
};
</script>
